<template>
  <div class="edittiku">
    <el-table
    :data="tableData"
  style="width: 100%"
  border
  stripe
>
      <el-table-column label="分类一" prop="classify_one"> </el-table-column>
      <el-table-column label="分类二" prop="classify_two"> </el-table-column>
      <el-table-column label="问题" prop="question">
        <template slot-scope="scope">
          <div class="fixed-row ellipsis">{{ scope.row.question }}</div>
        </template>
      </el-table-column>
      <el-table-column label="图片" prop="image_url">
        <template slot-scope="scope">
          <div class="fixed-row ellipsis" v-if="scope.row.image_url !== null">
          <img :src="getImageUrl(scope.row.image_url)" />
          </div>
          <div class="fixed-row ellipsis" v-else>null</div>
        </template>
      </el-table-column>
      <el-table-column label="选项" prop="options">
        <template slot-scope="scope">
          <div class="fixed-row ellipsis">{{ scope.row.options }}</div>
        </template>
      </el-table-column>
      <el-table-column label="答案" prop="correct_answer">
        <template slot-scope="scope">
          <div class="fixed-row ellipsis">{{ scope.row.correct_answer }}</div>
        </template>
         </el-table-column>
      <el-table-column align="right">
        <template slot="header" slot-scope="scope">
          <div class="search_input">
            <el-input
          v-model="search"
          size="mini"
          placeholder="输入关键字搜索"
          @change="Gridtodetails(scope.$index)"/>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="searchInput"></el-button>
          </div>
        </template>
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
            >Edit</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >Delete</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="parseInt(count)"
      >
      </el-pagination>
    </div>
    <el-dialog
  title="更新"
  :visible.sync="dialogVisible"
  width="60%"
 > 
  <div class="box-card">
 <el-row>
      <el-col :span="20">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          label-position="left"
        >
          <el-form-item label="分类一" prop="region1">
            <el-select v-model="ruleForm.region1" placeholder="请选择分类一">
              <el-option label="特种工" value="特种工"></el-option>
              <el-option label="八大员" value="八大员"></el-option>
              <el-option label="三类人员" value="三类人员"></el-option>
              <el-option label="技工" value="技工"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分类二" prop="region2">
            <el-select v-model="ruleForm.region2" placeholder="请选择分类二">
              <el-option :label="item.val" :value="item.val" v-for="item in region_two" :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="图片" prop="image">
            <el-upload
              class="avatar-uploader"
              action=""
              :show-file-list="false"
              :auto-upload="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :on-change="uploadimage"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="题目" prop="title">
            <el-input v-model="ruleForm.title"></el-input>
          </el-form-item>
          <el-form-item label="选项" prop="desc">
            <el-input
              type="textarea"
              v-model="ruleForm.desc"
              placeholder="请用 ；将各选项隔开"
            ></el-input>
          </el-form-item>
          <el-form-item label="答案" prop="answer">
            <el-input
              v-model="ruleForm.answer"
              placeholder="请填写完整答案    多选请用 ；将各答案隔开"
            ></el-input>
          </el-form-item>
          

          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提交修改</el-button
            >
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>

         

        </el-form>
      </el-col>
    </el-row>

  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
   
  </span>
</div>
</el-dialog>
  </div>
</template>

<script>
import config from "../../config";
export default {
  name: "EditTiku",
  data() {
    return {
      search: "",
      currentPage4: 1,
      count: "",
      page_num: "",
      page_now: "",
      tableData: [], // 用于存储格式化后的数据
      api: config.domain + "/",
      isSearch:false ,//判断是否在搜索下
      dialogVisible: false,
      //edit
      editId:'',
      ruleForm: {
        region1: "",
        region2: "",
        title: "",
        desc: "",
        answer: "",
        image: "",
       
      },
      imageUrl: "",
      imageFile: null,
      rules: {
        region1: [
          { required: true, message: "请选择分类一", trigger: "change" },
        ],
        region2: [
          { required: true, message: "请选择分类二", trigger: "change" },
        ],
        title: [{ required: true, message: "请输入题目", trigger: "blur" }],

        desc: [{ required: true, message: "请填写选项", trigger: "blur" }],
        answer: [{ required: true, message: "请填写答案", trigger: "blur" }],
      },
    };
  },
  methods: {
    getImageUrl(imagePath) {
      const api = this.api;
      return `${api}${imagePath}`;
    },
    handleEdit(index, row) {
      console.log(index, row);
      this.dialogVisible=true
      this.ruleForm.region1=row.classify_one
      this.ruleForm.region2=row.classify_two
      this.ruleForm.title=row.question
      this.ruleForm.desc=row.options
      this.ruleForm.answer=row.correct_answer
      this.imageUrl=this.api+row.image_url
      this.editId=row.id
    },
    handleDelete(index, row) {
      // console.log(index, row);
      this.$confirm("此操作将永久删除该题, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let result = await this.$axios("deletetiku", {
            id: row.id,
          });

          if (result.status === 200) {
            this.$message(result.message);

          } else {
            this.$message(result.message);

          }
          this.getTableData()
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.page_num = val;
      this.isSearch?this.searchInput():
      this.getTableData(); // 分页改变时重新获取数据
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.page_now = val;
      this.isSearch?this.searchInput():
      this.getTableData(); // 当前页改变时重新获取数据
    },
    async getTableData() {
      try {
        let res = await this.$axios("gettiku", {
          page: this.page_now,
          pageSize: this.page_num,
        });
        // console.log(res);
        this.count = res.count;
        let data = [];
        res.data.forEach((item) => {
          data.push({
            id:item.id,
            classify_one: item.classify_one,
            classify_two: item.classify_two,
            question: item.question,
            image_url: item.image_url,
            options: item.options,
            correct_answer: item.correct_answer,
          });
        });
        this.tableData = data; // 将获取到的数据赋值给tableData变量
      } catch (error) {
        console.error(error);
      }
    },
    async searchInput(){
      console.log(this.search)
      let res = await this.$axios("search", {
        search:this.search,
        page: this.page_now,
          pageSize: this.page_num
        });
        // console.log(res);
        this.count = res.count;
        this.isSearch=true
        let data = [];
        res.data.forEach((item) => {
          data.push({
            id:item.id,
            classify_one: item.classify_one,
            classify_two: item.classify_two,
            question: item.question,
            image_url: item.image_url,
            options: item.options,
            correct_answer: item.correct_answer,
          });
        });
        this.tableData = data; // 将获取到的数据赋值给tableData变量
        this.page_now=1
    },
     //select框选中值后,调用的函数
     Gridtodetails(val) {
    },

    //edit
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const formData = new FormData();
          formData.append('question', this.ruleForm.title);
          formData.append('image', this.imageFile);
          formData.append('options', this.ruleForm.desc);
          formData.append('correct_answer', this.ruleForm.answer);
          formData.append('classify_one', this.ruleForm.region1);
          formData.append('classify_two', this.ruleForm.region2);
          // formData.append('id', this.editId);
          let meta={
            id:this.editId,
            formData:formData
          }
          let result= await this.$axios("editTiku",meta)
          if (result.status === 200) {
            this.$message(result.message);
          } else {
            this.$message(result.message);
          }
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    uploadimage(file){
      // console.log(file)
      this.imageUrl = URL.createObjectURL(file.raw);
      this.imageFile = file.raw
      // console.log(this.imageFile)
    },
  },
  mounted() {
    this.getTableData(); // 组件挂载时获取数据
  },
  //edit
  computed:{
    region_two(){
      let data=[]
      if(this.ruleForm.region1=='特种工'){
        data.push(
          {
            id:1,
            val:'电梯安拆'
          },
          {
            id:2,
            val:'电梯司机'
          },{
            id:3,
            val:'吊篮安拆'
          },{
            id:4,
            val:'附着升降脚手架'
          },{
            id:5,
            val:'建筑电工'
          },{
            id:6,
            val:'普通脚手架'
          },{
            id:7,
            val:'司索信号工'
          },{
            id:8,
            val:'塔吊安拆'
          },{
            id:9,
            val:'塔吊司机'
          },{
            id:10,
            val:'物料提升机操作工'
          },
          )
      }else if(this.ruleForm.region1=='八大员'){
        data.push(
          {
            id:1,
            val:'安全员'
          },
          {
            id:2,
            val:'测量员'
          },{
            id:3,
            val:'见证取送试验'
          },{
            id:4,
            val:'预算员'
          },{
            id:5,
            val:'土建工程施工员'
          },{
            id:6,
            val:'土建工程质量员'
          },{
            id:7,
            val:'设备安装施工员'
          },{
            id:8,
            val:'设备安装质量员'
          },{
            id:9,
            val:'市政工程施工员'
          },{
            id:10,
            val:'市政工程质量员'
          },{
            id:11,
            val:'装饰装修施工员'
          },{
            id:12,
            val:'装饰装修质量员'
          },{
            id:13,
            val:'材料员'
          },{
            id:14,
            val:'机械员'
          },{
            id:15,
            val:'劳务员'
          },{
            id:16,
            val:'资料员'
          },{
            id:17,
            val:'标准员'
          }
          )
      }else if(this.ruleForm.region1=='三类人员'){
        data.push(
          {
            id:1,
            val:'A类(企业负责人)'
          },
          {
            id:2,
            val:'B类(项目负责人)'
          },
          {
            id:3,
            val:'C类(专职安全员)'
          }
          )
      }else{
        data.push(
          {
            id:1,
            val:'建筑瓦工'
          },
          {
            id:2,
            val:'砌筑工'
          },
          {
            id:3,
            val:'瓦工'
          },
          {
            id:4,
            val:'窑炉修筑工'
          },
          {
            id:5,
            val:'钢筋工'
          },{
            id:6,
            val:'混凝土工'
          },{
            id:7,
            val:'混凝土浇筑工'
          },{
            id:8,
            val:'混凝土搅拌工'
          },{
            id:9,
            val:'混凝土模具工'
          },{
            id:10,
            val:'混凝土模板工'
          },{
            id:11,
            val:'模板工'
          },{
            id:12,
            val:'机械设备安装工'
          },{
            id:13,
            val:'通风工'
          },{
            id:14,
            val:'安装钳工'
          },{
            id:15,
            val:'电气设备安装调试工'
          },{
            id:16,
            val:'管道工'
          },{
            id:17,
            val:'抹灰工'
          },{
            id:18,
            val:'油漆工'
          },{
            id:19,
            val:'镶贴工'
          },{
            id:20,
            val:'防水工'
          },{
            id:21,
            val:'木工'
          },{
            id:22,
            val:'手工木工'
          },{
            id:23,
            val:'精细木工'
          },{
            id:24,
            val:'测量放线工'
          },{
            id:25,
            val:'测量工'
          },{
            id:26,
            val:'石工'
          },{
            id:27,
            val:'石作业工'
          },{
            id:28,
            val:'匾额工'
          },{
            id:29,
            val:'木雕工'
          },{
            id:30,
            val:'泥塑工'
          },{
            id:31,
            val:'砌花街工'
          },{
            id:32,
            val:'石雕工'
          },{
            id:33,
            val:'彩绘工'
          },{
            id:34,
            val:'推光漆工'
          },{
            id:35,
            val:'砧刻工'
          },{
            id:36,
            val:'砧细工'
          },
          )
      }

      return data
    }
  }

};



</script>

<style lang="less" scoped>
.edittiku{
  width: 90%;
  padding: 5px 70px;

  
  
}
.el-table .fixed-row {
  height: 50px; /* 自定义固定行高度 */
}

.el-table .ellipsis {
  text-overflow: ellipsis; /* 文本超出部分显示省略号 */
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 隐藏超出的部分 */
  img {
    width: 100%;
    height: 100%;
  }
}
.search_input{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}


.box-card {
    width: 90%;
    margin-left: 5%;
    .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9 ;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  }
</style>