<template>
  <div class="User">
    <el-tabs v-model="activeName" @tab-click="handleClick"  type="card">
      <el-tab-pane label="用户" name="first"><uniUser></uniUser></el-tab-pane>
      <el-tab-pane label="管理员" name="second">
        <Admin></Admin>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import uniUser from './uniUser.vue'
import Admin from './Admin.vue'
export default {
  name: "User",
  components:{
    uniUser,
    Admin
  },
  data(){
    return{
      activeName: 'first'
    }
  },
  methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      }
    }
};
</script>

<style>
</style>