<template>
  <div class="Announcement">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>公告信息</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="update"
          >更新</el-button
        >
      </div>
      <el-form ref="form" >
      <el-form-item label="活动形式">
        <el-input type="textarea" v-model="desc"></el-input>
      </el-form-item>
    </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "Announcement",
  data() {
    return {
      getannouncement_data: [],
      desc:'',
      id:''
    };
  },
  async created() {
    let res = await this.$axios("getannouncement");
    console.log(res);
    this.desc=res[0].text
    this.id=res[0].id
  },
  methods:{
    update() {
      this.$confirm("此操作更新公告, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let result = await this.$axios("updateGetannouncement", {
            id: this.id,
            text:this.desc
          });

          if (result.status === 200) {
            this.$message(result.message);
          } else {
            this.$message(result.message);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消更新",
          });
        });
    },
  }
};
</script>

<style lang="less" scoped>
.box-card {
    width: 90%;
    margin-left: 5%;}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 480px;
}
</style>