import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activeIndex:'1-1',
  },
  mutations: {
    changeActiveIndex(state,params){
      state.activeIndex=params
    },
  },
  actions: {
  },
  modules: {
  }
})
