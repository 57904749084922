<template>
    <div class="System_Nav">
      <div class="nav_body">
        <el-row class="nav">
          <el-col :span="3">
            <div class="logo">题库教育管理系统</div>
          </el-col>
          <el-col :offset="17" :span="3" class="username">{{username}}</el-col>
        </el-row>
        <el-row class="body">
          <el-col :span="3" class="left_nav">
            <el-menu
              :default-active="activeIndex.split('-')[0]"
              background-color="rgba(0,0,0,0)"
              text-color="#555"
              @select="selectAction"
            >
              <el-menu-item index="1" class="title">
                <i class="el-icon-user-solid"></i>
                <span slot="title">用户管理</span>
              </el-menu-item>
              <el-menu-item index="2" class="title">
                <i class="el-icon-s-marketing"></i>
                <span slot="title">展示管理</span>
              </el-menu-item>
              <el-menu-item index="3" class="title">
                <i class="el-icon-s-order"></i>
                <span slot="title">题库管理</span>
              </el-menu-item>
            </el-menu>
          </el-col>
          <el-col :span="21" class="right_body" :offset="3">
            <router-view></router-view>
          </el-col>
        </el-row>
        
      </div>
    </div>
  </template>
  
  <script>
  // 导入辅助函数
  import { mapState } from "vuex";

  export default {
    name: "SystemNav",
    data(){
      return{
        username:'xxx'
      }
    },
    methods: {
      selectAction(index) {
        // 编程式导航
        if (index == 1) {
          this.$router.replace({ name: "User" });
        } else if (index == 2) {
          this.$router.replace({ name: "Show" });
        } else {
          this.$router.replace({ name: "Tiku" });
        }
      },
    },
    components: {
     
    },
    computed: {
      ...mapState(["activeIndex"]),
    },
    created(){
      this.username=sessionStorage.getItem('token')
    }
  };
  </script>
  
  <style lang="less" >
  .System_Nav {
    .nav_body {
    //   height: 60px;
      .nav {
        background-color: #5e7997;
        width: 100%;
        height: 6vh;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 999;
        font-size: 0;
        .logo{
            width: 100%;
        font-size: 1.5vw;
        font-weight: bold;
        color: white;
        line-height: 6vh;
        }
      }
      
      .username {
        text-align: right;
        margin-top: 20px;
        padding-right: 12px;
        font-size: 16px;
        color: white !important;
      }
    }
    .body {
      margin-top: 6vh;
    }
    .left_nav {
      margin-top: 6vh;
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 999;
      background-color: #eef3f6;
      // background-color: #001529;
      color:white !important;
      .title{
        font-size: 0.9vw;
      }
    }
    .is-active {
      background-color: white !important;
      // color:white !important;
    }
    
    .el-dialog__warper {
      z-index: 1000 !important;
      background-color: rgb(127, 127, 129);
      // background-color: #1890ff !important;

    }
    .el-dialog__header{
      text-align: left !important;
    }
    
  }
  </style>