<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

*{
  margin: 0;
  padding: 0;
}
</style>

<script>
export default {
  name:'App',
  watch:{
      $route:{
        handler(newV){
          this.$store.commit("changeActiveIndex",newV.meta.activeIndex)
        },
        immediate:true
      }
    }
}
</script>