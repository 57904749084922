import Vue from 'vue'
import { Button, Select, Row, Col, Menu, Submenu, MenuItemGroup, MenuItem, Carousel,CarouselItem 
    ,Breadcrumb,BreadcrumbItem,Dialog,Upload,TabPane,Tabs,Form,FormItem,Input,Option,Table,TableColumn,Pagination,InputNumber,Card,Popover,Tag} from 'element-ui'

Vue.use(Button)
Vue.use(Select)
Vue.use(Row)
Vue.use(Col)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(MenuItem)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Dialog)
Vue.use(Upload)
Vue.use(TabPane)
Vue.use(Tabs)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Option)
Vue.use(Option)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(InputNumber)
Vue.use(Card)
Vue.use(Popover)
Vue.use(Tag)
