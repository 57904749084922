<template>
  <div></div>
</template>

<script>
export default {
    name:'Admin'
}
</script>

<style>

</style>