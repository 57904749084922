<template>
  <div class="showpage">
    <el-card class="box-card">
    <div class="top_banner">
      <el-carousel :interval="4000" type="card" height="200px">
        <el-carousel-item v-for="item in banners" :key="item.id">
          <img :src="getImageUrl(item.image_url)" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="add">
      <el-button type="primary" @click="dialogVisible = true">+新建</el-button>
    </div>
    <div class="list">
      <div class="list_one">
        <div class="left">序号</div>
        <div class="center">图片预览</div>
        <div class="right">操作</div>
      </div>
      <div class="listItem" v-for="(item, index) in banners" :key="item.id">
        <div class="left">{{ index + 1 }}</div>
        <div class="center">
          <img :src="getImageUrl(item.image_url)" />
        </div>
        <div class="right">
          <el-button type="danger" @click="deleteBanner(item.id)"
            >删除</el-button
          >
        </div>
      </div>
    </div>
    <el-dialog title="请上传轮播图" :visible.sync="dialogVisible" width="30%" @closed="handleClosed">
      <el-upload
        class="upload-demo"
        :action="yourUploadUrl"
        :before-upload="handleBeforeUpload"
        :on-success="handleSuccess"
        :on-error="handleError"
        :file-list="fileList"
        list-type="picture"
      >
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">
          只能上传jpg/png文件，且不超过500kb
        </div>
      </el-upload>
    </el-dialog>
  </el-card>
  </div>
</template>

<script>
import config from "../../config";
import axios from "axios";
export default {
  name: "Show",
  data() {
    return {
      banners: [],
      api: config.domain + "/",
      dialogVisible: false,
      fileList: [],
    };
  },
  async created() {
    this.banners = await this.$axios("getbanner");
  },
  methods: {
    getImageUrl(imagePath) {
      const api = this.api;
      return `${api}${imagePath}`;
    },
    deleteBanner(id) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let result = await this.$axios("deletebanner", {
            id: id,
          });

          if (result.status === 200) {
            this.$message(result.message);
          } else {
            this.$message(result.message);
          }
          this.banners = this.banners.filter((obj) => obj.id !== id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //新增轮播图
    handleBeforeUpload(file) {
      // 在上传之前做一些逻辑判断
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt500K = file.size / 1024 <= 500;

      if (!isJPG && !isPNG) {
        this.$message.error("只能上传 JPG 或 PNG 格式的图片");
        return false;
      }

      if (!isLt500K) {
        this.$message.error("图片大小不能超过 500KB");
        return false;
      }

      const formData = new FormData();
      formData.append("image", file); // 将文件添加到 FormData 对象中，键名为 'image'

      this.uploadFile(formData); // 调用上传文件的方法

      return false; // 返回 false 阻止默认上传行为
    },
    async uploadFile(formData) {
      // 发送请求上传文件
      // 例如使用 axios 进行请求
      axios
        .post(this.yourUploadUrl, formData)
        .then((response) => {
          // 文件上传成功的处理逻辑
          this.$message(response.data.message);
          this.fileList.push({
            name: 1,
            url: `${this.api}${response.data.url}`,
          });
        })
        .catch((error) => {
          // 文件上传失败的处理逻辑
          this.$message(response.data.message);
        });
    },

    handleSuccess(response, file, fileList) {
      // 图片上传成功的回调
    },
    handleError(error, file, fileList) {
      // 图片上传失败的回调
      this.fileList = fileList; // 更新文件列表
    },
    //关闭dialog
    handleClosed() {
      if(this.fileList.length===0){
      
      }else{
      location.reload();
      }
  }
  },
  computed: {
    yourUploadUrl() {
      // 返回您自己的图片上传接口地址
      return `${this.api}banner/add`;
    },
  },
};
</script>

<style  lang="less" scoped>
.box-card {
    width: 96%;
    margin-left: 2%;
  }
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.el-breadcrumb__item {
  margin-top: 10px;
  margin-left: 10px;
  span {
    font-size: 1.2vw;
  }
}
.top_banner {
  padding: 0 30px;
  box-sizing: border-box;
}
.add {
  padding: 0 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: left;
}
.list {
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  .list_one {
    display: flex;
    height: 5vh;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dad9d9;
    font-weight: bold;
    color: #bec2c7;
  }
  .listItem {
    width: 100%;
    // background-color: #f0efef;
    border-bottom: 1px solid #f0efef;
    height: 12vh;
    display: flex;
    justify-content: space-between;
    .left,
    .right {
      line-height: 12vh;
    }
    .center img {
      width: 30vw;
      height: 100%;
    }
  }
}
</style>