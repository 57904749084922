<template>
    <div class="show">
      <el-tabs v-model="activeName" @tab-click="handleClick"  type="card">
        <el-tab-pane label="轮播图管理" name="first"><BannerShow></BannerShow></el-tab-pane>
        <el-tab-pane label="公告管理" name="second">
          <Announcement></Announcement>
        </el-tab-pane>
      </el-tabs>
    </div>
  </template>
  
  <script>
  import BannerShow from "./bannerShow.vue"
  import Announcement from './Announcement.vue'
  export default {
    name: "Tiku",
    components:{
        BannerShow,
        Announcement
    },
    data(){
      return{
        activeName: 'first'
      }
    },
    methods: {
        handleClick(tab, event) {
          console.log(tab, event);
        }
      }
  };
  </script>
  
  <style>
  </style>