import Vue from 'vue'
import VueRouter from 'vue-router'
import SystemNav from '../views/SystemNav.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'SystemNav',
    component: SystemNav,
    redirect: { name: "User" },
    children: [
      {
        path: 'USER',
        name: 'User',
        meta:{activeIndex:'1'},
        component:()=>import("@/views/User/User.vue"),
      },
      {
        path: 'SHOW',
        name: 'Show',
        meta:{activeIndex:'2'},
        component:()=>import("@/views/Show/Show.vue"),
      },
      {
        path: 'TIKU',
        name: 'Tiku',
        meta:{activeIndex:'3'},
        component:()=>import("@/views/Tiku/Tiku.vue"),
      },
    ]
  },
  {
    path: '/LOGIN',
    name: 'Login',
    component:()=>import("@/views/Login.vue"),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//路由导航守卫
router.beforeEach((to,from,next)=>{
  if(to.name!="Login"){
    const state=sessionStorage.getItem('token');
    if(state){
      next()
    }else{
      router.replace({name:"Login"})
    }
  }else{
    next()
  }
})

export default router
