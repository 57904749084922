//导入网络请求
import axios from "axios"
//导入网络请求配置
import apis from "./config.js"
import config from "../config"
//创建axios实例
const ax =axios.create({
    timeout:6000,
    baseURL:config.domain
})
//为当前实例添加请求拦截器
ax.interceptors.request.use(function(config){
    return config;
},function(error){
    return Promise.reject(error)
});
//响应拦截器
ax.interceptors.response.use(function(response){
    return response.data
},function(error){
    return Promise.reject(error)
})

//定义函数完成网络请求
function startNetWork(params){
    return ax({
        method:"GET",
        ...params
    })
}

//动态遍历apis
const works={meta:{}};//meta用来进行动态数据传递
for(let key in apis){
    Object.defineProperty(works,key,{
       async get(){
            let fun =apis[key];
            let result =await startNetWork(fun(this.meta))
            this.meta={}//清除上一刻meta中存储的数据
            return result
        }
    })
}

//定义函数用来获取对应的数据
async function getDatas(keyName,meta={}){
    works.meta=meta //设置请求之前需要传递的数据
    let datas=await works[keyName] //开始获取指定键值对应的数据
    return datas

}

export default getDatas