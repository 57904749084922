<template>
  <div class="addTiku">
    <el-card class="box-card">
    <el-row>
      <el-col :span="12">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          label-position="left"
        >
          <el-form-item label="分类一" prop="region1">
            <el-select v-model="ruleForm.region1" placeholder="请选择分类一">
              <el-option label="特种工" value="特种工"></el-option>
              <el-option label="八大员" value="八大员"></el-option>
              <el-option label="三类人员" value="三类人员"></el-option>
              <el-option label="技工" value="技工"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分类二" prop="region2">
            <el-select v-model="ruleForm.region2" placeholder="请选择分类二">
              <el-option :label="item.val" :value="item.val" v-for="item in region_two" :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="图片" prop="image">
            <el-upload
              class="avatar-uploader"
              action=""
              :show-file-list="false"
              :auto-upload="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :on-change="uploadimage"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="题目" prop="title">
            <el-input v-model="ruleForm.title"></el-input>
          </el-form-item>
          <el-form-item label="选项" prop="desc">
            <el-input
              type="textarea"
              v-model="ruleForm.desc"
              placeholder="请用 ；将各选项隔开"
            ></el-input>
          </el-form-item>
          <el-form-item label="答案" prop="answer">
            <el-input
              v-model="ruleForm.answer"
              placeholder="请填写完整答案    多选请用 ；将各答案隔开"
            ></el-input>
          </el-form-item>
          

          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >立即创建</el-button
            >
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>

          <el-form-item>
          <el-button type="primary" @click="dialogVisible = true"
            >批量导入</el-button
          >
        </el-form-item>

        </el-form>
      </el-col>
    </el-row>

    <el-dialog
        title="请批量导入excel表格"
        :visible.sync="dialogVisible"
        width="30%"
      >
        <div>
          <input type="file" ref="uploadInput" @change="handleFileChange" />
          <el-button type="primary" @click="handleUpload">点击上传</el-button>
        </div>
      </el-dialog>

  </el-card>
  </div>
</template>

<script>
export default {
  name: "AddTiku",
  data() {
    return {
      selectedFile: null,
      dialogVisible: false,
      ruleForm: {
        region1: "",
        region2: "",
        title: "",
        desc: "",
        answer: "",
        image: "",
       
      },
      imageUrl: "",
      imageFile: null,
      rules: {
        region1: [
          { required: true, message: "请选择分类一", trigger: "change" },
        ],
        region2: [
          { required: true, message: "请选择分类二", trigger: "change" },
        ],
        title: [{ required: true, message: "请输入题目", trigger: "blur" }],

        desc: [{ required: true, message: "请填写选项", trigger: "blur" }],
        answer: [{ required: true, message: "请填写答案", trigger: "blur" }],
      },
    };
  },
  methods: {
   submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const formData = new FormData();
          formData.append('question', this.ruleForm.title);
          formData.append('image', this.imageFile);
          formData.append('options', this.ruleForm.desc);
          formData.append('correct_answer', this.ruleForm.answer);
          formData.append('classify_one', this.ruleForm.region1);
          formData.append('classify_two', this.ruleForm.region2);
          let result= await this.$axios("addtiku",formData)
          if (result.status === 200) {
            this.$message(result.message);
          } else {
            this.$message(result.message);
          }
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    uploadimage(file){
      // console.log(file)
      this.imageUrl = URL.createObjectURL(file.raw);
      this.imageFile = file.raw
      // console.log(this.imageFile)
    },
    //批量导入
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
    async handleUpload() {
      if (this.selectedFile) {
        const formData = new FormData();
        formData.append("file", this.selectedFile);

        try {
          const res = await this.$axios("importTiku", formData);
          this.$message(res.message);
        } catch (error) {
          console.error(error);
          this.$message(error);

        }
      }
    },
  },
  computed:{
    region_two(){
      let data=[]
      if(this.ruleForm.region1=='特种工'){
        data.push(
          {
            id:1,
            val:'电梯安拆'
          },
          {
            id:2,
            val:'电梯司机'
          },{
            id:3,
            val:'吊篮安拆'
          },{
            id:4,
            val:'附着升降脚手架'
          },{
            id:5,
            val:'建筑电工'
          },{
            id:6,
            val:'普通脚手架'
          },{
            id:7,
            val:'司索信号工'
          },{
            id:8,
            val:'塔吊安拆'
          },{
            id:9,
            val:'塔吊司机'
          },{
            id:10,
            val:'物料提升机操作工'
          },
          )
      }else if(this.ruleForm.region1=='八大员'){
        data.push(
          {
            id:1,
            val:'安全员'
          },
          {
            id:2,
            val:'测量员'
          },{
            id:3,
            val:'见证取送试验'
          },{
            id:4,
            val:'预算员'
          },{
            id:5,
            val:'土建工程施工员'
          },{
            id:6,
            val:'土建工程质量员'
          },{
            id:7,
            val:'设备安装施工员'
          },{
            id:8,
            val:'设备安装质量员'
          },{
            id:9,
            val:'市政工程施工员'
          },{
            id:10,
            val:'市政工程质量员'
          },{
            id:11,
            val:'装饰装修施工员'
          },{
            id:12,
            val:'装饰装修质量员'
          },{
            id:13,
            val:'材料员'
          },{
            id:14,
            val:'机械员'
          },{
            id:15,
            val:'劳务员'
          },{
            id:16,
            val:'资料员'
          },{
            id:17,
            val:'标准员'
          }
          )
      }else if(this.ruleForm.region1=='三类人员'){
        data.push(
          {
            id:1,
            val:'A类(企业负责人)'
          },
          {
            id:2,
            val:'B类(项目负责人)'
          },
          {
            id:3,
            val:'C类(专职安全员)'
          }
          )
      }else{
        data.push(
          {
            id:1,
            val:'建筑瓦工'
          },
          {
            id:2,
            val:'砌筑工'
          },
          {
            id:3,
            val:'瓦工'
          },
          {
            id:4,
            val:'窑炉修筑工'
          },
          {
            id:5,
            val:'钢筋工'
          },{
            id:6,
            val:'混凝土工'
          },{
            id:7,
            val:'混凝土浇筑工'
          },{
            id:8,
            val:'混凝土搅拌工'
          },{
            id:9,
            val:'混凝土模具工'
          },{
            id:10,
            val:'混凝土模板工'
          },{
            id:11,
            val:'模板工'
          },{
            id:12,
            val:'机械设备安装工'
          },{
            id:13,
            val:'通风工'
          },{
            id:14,
            val:'安装钳工'
          },{
            id:15,
            val:'电气设备安装调试工'
          },{
            id:16,
            val:'管道工'
          },{
            id:17,
            val:'抹灰工'
          },{
            id:18,
            val:'油漆工'
          },{
            id:19,
            val:'镶贴工'
          },{
            id:20,
            val:'防水工'
          },{
            id:21,
            val:'木工'
          },{
            id:22,
            val:'手工木工'
          },{
            id:23,
            val:'精细木工'
          },{
            id:24,
            val:'测量放线工'
          },{
            id:25,
            val:'测量工'
          },{
            id:26,
            val:'石工'
          },{
            id:27,
            val:'石作业工'
          },{
            id:28,
            val:'匾额工'
          },{
            id:29,
            val:'木雕工'
          },{
            id:30,
            val:'泥塑工'
          },{
            id:31,
            val:'砌花街工'
          },{
            id:32,
            val:'石雕工'
          },{
            id:33,
            val:'彩绘工'
          },{
            id:34,
            val:'推光漆工'
          },{
            id:35,
            val:'砧刻工'
          },{
            id:36,
            val:'砧细工'
          },
          )
      }

      return data
    }
  }
};
</script>

<style lang="less" >
.box-card {
    width: 90%;
    margin-left: 5%;
    .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9 ;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  }

</style>