import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css'
import "./element"
import {Message} from 'element-ui'
import {MessageBox} from 'element-ui'

// 导入封装网络请求
import getDatas from "@/network/index.js"
// 挂载网络请求
Vue.prototype.$axios=getDatas
Vue.prototype.$message=Message
Vue.prototype.$confirm=MessageBox


Vue.config.productionTip = false




new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
