<template>
  <div>
    <div class="top" style="display: flex; justify-content: left">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="姓名">
          <el-input v-model="formInline.user" placeholder="姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="formInline.phone" placeholder="手机号"></el-input>
        </el-form-item>
        <el-form-item label="分类一">
          <el-select v-model="formInline.region1" placeholder="请选择分类一">
            <el-option label="特种工" value="特种工"></el-option>
            <el-option label="八大员" value="八大员"></el-option>
            <el-option label="三类人员" value="三类人员"></el-option>
            <el-option label="技工" value="技工"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类二">
          <el-select v-model="formInline.region2" placeholder="请选择分类二">
            <el-option
              :label="item.val"
              :value="item.val"
              v-for="item in region_two"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="formInline.status" placeholder="请选择状态">
            <el-option label="批量导入" value="0"></el-option>
            <el-option label="注册添加" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchInput">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="dialogVisible = true"
            >批量导入</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="exportUsers">批量导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="edittiku">
      <el-table
        :data="tableData"
        border
        stripe
        style="width: 90%; margin: 0 5%; box-sizing: border-box"
      >
        <el-table-column label="状态" width="200">
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <span style="margin-left: 10px" v-if="scope.row.status == 0">
              批量导入
            </span>
            <span style="margin-left: 10px" v-else>注册添加</span>
          </template>
        </el-table-column>
        <el-table-column label="姓名" width="200">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <p>姓名: {{ scope.row.username }}</p>
              <p>手机号: {{ scope.row.phone_number }}</p>
              <div slot="reference" class="name-wrapper">
                <el-tag size="medium">{{ scope.row.username }}</el-tag>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          label="手机号"
          prop="phone_number"
          width="200"
        ></el-table-column>
        <el-table-column
          label="工种"
          prop="category"
          width="200"
        ></el-table-column>
        <el-table-column
          label="登录时间"
          prop="login_time"
          width="200"
        ></el-table-column>
        <el-table-column
          label="进度(百分比)"
          prop="schedule"
          width="200"
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="parseInt(count)"
        >
        </el-pagination>
      </div>
      <el-dialog
        title="请批量导入excel表格"
        :visible.sync="dialogVisible"
        width="30%"
      >
        <div>
          <input type="file" ref="uploadInput" @change="handleFileChange" />
          <el-button type="primary" @click="handleUpload">点击上传</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
  
  <script>
import config from "../../config";

export default {
  name: "uniUser",
  data() {
    return {
      selectedFile: null,
      dialogVisible: false,
      api: config.domain + "/",
      formInline: {
        user: "",
        phone: "",
        region1: "",
        region2: "",
        status: "",
      },
      currentPage4: 1,
      count: "",
      page_num: "",
      page_now: "",
      tableData: [], // 用于存储格式化后的数据

      isSearch: false, //判断是否在搜索下
    };
  },
  methods: {
    //时间转换
    convertUTCtoLocal(utcDateString) {
    const utcDate = new Date(utcDateString); // 解析 ISO 8601 时间字符串
    const year = utcDate.getFullYear();
    const month = utcDate.getMonth() + 1; // 月份从 0 开始，所以要加 1
    const day = utcDate.getDate();
    const hour = utcDate.getHours();
    const minute = utcDate.getMinutes();
    
    // 格式化为年月日时分的字符串
    const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day} ${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}`;
    
    return formattedDate;
},
    handleEdit(index, row) {
      // console.log(index, row);
    },
    handleDelete(index, row) {
      // console.log(index, row);
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let result = await this.$axios("delete_users", {
            id: row.id,
          });

          if (result.status === 200) {
            this.$message(result.message);
          } else {
            this.$message(result.message);
          }
          this.getTableData();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.page_num = val;
      this.isSearch ? this.searchInput() : this.getTableData(); // 分页改变时重新获取数据
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.page_now = val;
      this.isSearch ? this.searchInput() : this.getTableData(); // 当前页改变时重新获取数据
    },
    async getTableData() {
      try {
        let res = await this.$axios("get_users", {
          page: this.page_now,
          pageSize: this.page_num,
        });
        console.log(res);
        this.count = res.count;
        let data = [];
        res.data.forEach((item) => {
          const percentage = ((Number(item.questions_attempted) / Number(item.total_questions)) * 100).toFixed(2);
          data.push({
            id: item.id,
            username: item.username,
            phone_number: item.phone_number,
            category: item.category,
            status: item.status,
            login_time:this.convertUTCtoLocal(item.login_time),
            schedule: parseFloat(percentage)? parseFloat(percentage):0// 将百分比字符串转换为数字
          });
        });
        this.tableData = data; // 将获取到的数据赋值给tableData变量
      } catch (error) {
        console.error(error);
      }
    },

    //select框选中值后,调用的函数
    async searchInput() {
      let res = await this.$axios("search_users", {
        search_name: this.formInline.user,
        search_phone: this.formInline.phone,
        search_classify_two: this.formInline.region2,
        status: this.formInline.status,
        page: this.page_now,
        pageSize: this.page_num,
      });
      // console.log(res);
      this.count = res.count;
      this.isSearch = true;
      let data = [];
      res.data.forEach((item) => {
        const percentage = ((Number(item.questions_attempted) / Number(item.total_questions)) * 100).toFixed(2);
        data.push({
          id: item.id,
          username: item.username,
          phone_number: item.phone_number,
          category: item.category,
          status: item.status,
          login_time:this.convertUTCtoLocal(item.login_time),
          schedule: parseFloat(percentage)? parseFloat(percentage):0// 将百分比字符串转换为数字
        });
      });
      this.tableData = data; // 将获取到的数据赋值给tableData变量
      this.page_now = 1;
    },
    //批量导入
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
    async handleUpload() {
      if (this.selectedFile) {
        const formData = new FormData();
        formData.append("file", this.selectedFile);

        try {
          const res = await this.$axios("importUsers", formData);
          this.$message(res.message);
        } catch (error) {
          this.$message(error);
        }
      }
    },
    //批量导出
    exportUsers() {
      const link = document.createElement("a");
      link.href = `${this.api}users/exportUsers`;
      link.setAttribute("download", "users.xlsx");
      link.style.display = "none";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  mounted() {
    this.getTableData(); // 组件挂载时获取数据
  },
  computed: {
    region_two(){
      let data=[]
      if(this.formInline.region1=='特种工'){
        data.push(
          {
            id:1,
            val:'电梯安拆'
          },
          {
            id:2,
            val:'电梯司机'
          },{
            id:3,
            val:'吊篮安拆'
          },{
            id:4,
            val:'附着升降脚手架'
          },{
            id:5,
            val:'建筑电工'
          },{
            id:6,
            val:'普通脚手架'
          },{
            id:7,
            val:'司索信号工'
          },{
            id:8,
            val:'塔吊安拆'
          },{
            id:9,
            val:'塔吊司机'
          },{
            id:10,
            val:'物料提升机操作工'
          },
          )
      }else if(this.formInline.region1=='八大员'){
        data.push(
          {
            id:1,
            val:'安全员'
          },
          {
            id:2,
            val:'测量员'
          },{
            id:3,
            val:'见证取送试验'
          },{
            id:4,
            val:'预算员'
          },{
            id:5,
            val:'土建工程施工员'
          },{
            id:6,
            val:'土建工程质量员'
          },{
            id:7,
            val:'设备安装施工员'
          },{
            id:8,
            val:'设备安装质量员'
          },{
            id:9,
            val:'市政工程施工员'
          },{
            id:10,
            val:'市政工程质量员'
          },{
            id:11,
            val:'装饰装修施工员'
          },{
            id:12,
            val:'装饰装修质量员'
          },{
            id:13,
            val:'材料员'
          },{
            id:14,
            val:'机械员'
          },{
            id:15,
            val:'劳务员'
          },{
            id:16,
            val:'资料员'
          },{
            id:17,
            val:'标准员'
          }
          )
      }else if(this.formInline.region1=='三类人员'){
        data.push(
          {
            id:1,
            val:'A类(企业负责人)'
          },
          {
            id:2,
            val:'B类(项目负责人)'
          },
          {
            id:3,
            val:'C类(专职安全员)'
          }
          )
      }else{
        data.push(
          {
            id:1,
            val:'建筑瓦工'
          },
          {
            id:2,
            val:'砌筑工'
          },
          {
            id:3,
            val:'瓦工'
          },
          {
            id:4,
            val:'窑炉修筑工'
          },
          {
            id:5,
            val:'钢筋工'
          },{
            id:6,
            val:'混凝土工'
          },{
            id:7,
            val:'混凝土浇筑工'
          },{
            id:8,
            val:'混凝土搅拌工'
          },{
            id:9,
            val:'混凝土模具工'
          },{
            id:10,
            val:'混凝土模板工'
          },{
            id:11,
            val:'模板工'
          },{
            id:12,
            val:'机械设备安装工'
          },{
            id:13,
            val:'通风工'
          },{
            id:14,
            val:'安装钳工'
          },{
            id:15,
            val:'电气设备安装调试工'
          },{
            id:16,
            val:'管道工'
          },{
            id:17,
            val:'抹灰工'
          },{
            id:18,
            val:'油漆工'
          },{
            id:19,
            val:'镶贴工'
          },{
            id:20,
            val:'防水工'
          },{
            id:21,
            val:'木工'
          },{
            id:22,
            val:'手工木工'
          },{
            id:23,
            val:'精细木工'
          },{
            id:24,
            val:'测量放线工'
          },{
            id:25,
            val:'测量工'
          },{
            id:26,
            val:'石工'
          },{
            id:27,
            val:'石作业工'
          },{
            id:28,
            val:'匾额工'
          },{
            id:29,
            val:'木雕工'
          },{
            id:30,
            val:'泥塑工'
          },{
            id:31,
            val:'砌花街工'
          },{
            id:32,
            val:'石雕工'
          },{
            id:33,
            val:'彩绘工'
          },{
            id:34,
            val:'推光漆工'
          },{
            id:35,
            val:'砧刻工'
          },{
            id:36,
            val:'砧细工'
          },
          )
      }

      return data
    }
  },
};
</script>
  
  <style lang="less" scoped>
.edittiku {
  width: 90%;
  padding: 5px 70px;
}
.el-table .fixed-row {
  height: 50px; /* 自定义固定行高度 */
}

.el-table .ellipsis {
  text-overflow: ellipsis; /* 文本超出部分显示省略号 */
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 隐藏超出的部分 */
  img {
    width: 100%;
    height: 100%;
  }
}
.search_input {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
</style>