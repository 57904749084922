<template>
  <div class="tiku">
    <el-tabs v-model="activeName" @tab-click="handleClick"  type="card">
      <el-tab-pane label="题库管理" name="first"><EditTiku></EditTiku></el-tab-pane>
      <el-tab-pane label="题库新增" name="second">
        <AddTiku></AddTiku>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import AddTiku from './addTiku.vue'
import EditTiku from './editTiku.vue'
export default {
  name: "Tiku",
  components:{
    AddTiku,
    EditTiku
  },
  data(){
    return{
      activeName: 'first'
    }
  },
  methods: {
      handleClick(tab, event) {
        // console.log(tab, event);
      }
    }
};
</script>

<style>
</style>