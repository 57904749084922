//完成项目中网络的请求配置
const apis = {
    getbanner() {
        return {
            url: `/banner`,
        }
    },
    deletebanner(meta){
        return{
            url:`/banner/delete/${meta.id}`,
            method: 'delete'
        }
    },
    addtiku(meta){
        return{
            url:`/tiku/add`,
            method:'post',
            data:meta
        }
    },
    gettiku(meta){
        return{
            url:`/tiku?page=${meta.page}&pageSize=${meta.pageSize}`,

        }
    },
    deletetiku(meta){
        return{
            url:`/tiku/delete/${meta.id}`,
            method: 'delete'
        }
    },
    search(meta){
        return{
            url:`/tiku/search?search=${meta.search}&page=${meta.page}&pageSize=${meta.pageSize}`
        }
    },
    getannouncement(){
        return{
            url:`/announcement`
        }
    },
    updateGetannouncement(meta){
        return{
            url:`/announcement/update/${meta.id}`,
            method:'put',
            data:{
                text:meta.text
            }
        }
    },
    get_users(meta){
        return{
            url:`/users?page=${meta.page}&pageSize=${meta.pageSize}`
        }
    },
    delete_users(meta){
        return{
            url:`/users/delete/${meta.id}`,
            method: 'delete'
        }
    },
    search_users(meta){
        return{
            url:`users/search?search_name=${meta.search_name}&search_phone=${meta.search_phone}&search_classify_two=${meta.search_classify_two}&status=${meta.status}&page=${meta.page}&pageSize=${meta.pageSize}`
        }
    },
    importUsers(meta){
        return{
            url:`users/importUsers`,
            method:'post',
            data:meta
        }
    },
    exportUsers(meta){
        return{
            url:'users/exportUsers',
            data:meta
        }
    },
    importTiku(meta){
        return{
            url:`tiku/importTiku`,
            method:'post',
            data:meta
        }
    },
    editTiku(meta){
        return{
            url:`tiku/questions/${meta.id}`,
            method:'put',
            data:meta.formData
        }
    }
}

export default apis